// icons
import { IconHeart, IconShoppingBag, IconShoppingBagPlus, IconStar } from "@tabler/icons-react";
// link
import { Link } from "react-router";
// spliter
import { splitNumber } from "../../features/splitNumber/splitNumber";

const ProductsCard = (props: any) => {
    return (
        <>
            <Link to={`/products/detail/${props.slug}`} className="text-decoration-none">
                <div data-aos="fade-right"
                    className="card new-product-card category-card border-0 rounded rounded-4 shadow">
                    <div className=" rounded rounded-4 p-3">
                        <span className="badge bg-white text-dark icon shadow">محصولات</span>
                        <div className="d-flex justify-content-center">
                            <img src={props.primary_image} alt="product" />
                        </div>
                        <div className="color-place icon">
                            <div className="like-place shadow">
                                <IconHeart size={18} />
                            </div>
                            <div className="like-place mt-2 shadow">
                                <IconShoppingBagPlus size={18} />
                            </div>
                        </div>
                    </div>
                    <div className="card-body mt-1">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-dark w-100 text-split fw-bold font-medium-lg mb-0">
                                {props.name}
                            </span>
                            <div className="stars text-end w-100 text-warning font-medium-lg align-middle">
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                            </div>
                        </div>
                        <p className="card-text text-split font-medium mb-0 mt-2">
                            {props.description}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <IconShoppingBag className="align-middle" />
                            {props.quantity_check?.price ? (
                                <span className="card-title price font-medium-lg">{splitNumber(props.quantity_check?.price)}  تومان</span>
                            ) : (
                                <span className="card-title text-danger fw-bold price font-medium-lg">نا موجود</span>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}
export default ProductsCard;