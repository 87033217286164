import { Keyboard, Pagination } from "swiper/modules";

export const ProductSettings = {
    slidesPerView: 4,
    centeredSlides: false,
    slidesPerGroupSkip: 1,
    spaceBetween: 30,
    grabCursor: true,
    keyboard: {
        enabled: true,
    },
    breakpoints: {
        176: { slidesPerView: 1.3 },
        768: { slidesPerView: 2 },
        1000: { slidesPerView: 5 },
    },
    pagination: {
        clickable: true,
    },
    modules: [Keyboard, Pagination],
    className: "mySwiper pb-4",
};

export const ProductsSettings = {
    slidesPerView: 4,
    centeredSlides: false,
    slidesPerGroupSkip: 1,
    spaceBetween: 30,
    grabCursor: true,
    keyboard: {
        enabled: true,
    },
    breakpoints: {
        176: { slidesPerView: 1.3 },
        768: { slidesPerView: 2 },
        1000: { slidesPerView: 4 },
    },
    pagination: {
        clickable: true,
    },
    modules: [Keyboard, Pagination],
    className: "mySwiper pb-4",
};
