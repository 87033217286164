import { QueryClient } from '@tanstack/react-query';

export const queryClient: any = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: 0,
            retryDelay: 1000 * 5,
        },
        mutations: {
            retry: 0,
            retryDelay: 1000 * 5,
        },
    },
});
