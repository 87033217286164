// link
import { Link } from "react-router";
// style
import './style/header.css'
// routes
import { RoutesClient } from "../../features/routes/routes";
// icon
import { IconDiscount, IconShoppingBag, IconUserCircle, IconX } from "@tabler/icons-react";
// hook
import { useState } from "react";
// mobile nav
import MobileNav from "../../components/mobileNav/mobileNav";
import HeaderNav from "../../components/headerNav/headerNav";
// controller
import useGetData from "../../hook/getData";
import { GetCategories } from "../../services/client/categories/getCategories";

const Header = () => {
    const [offer, setOffer] = useState<boolean>(false)
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const ChangeStatus = () => {
        setOffer(true);
    };
    const toggleMenu = () => {
        setIsMenuOpen(true);
    };
    const closeMenu = () => {
        setIsMenuOpen(false);
    };
    const { data: categoires } = useGetData(['categories'], GetCategories)
    return (
        <>
            <header className="position-sticky" onMouseMove={() => toggleMenu()} onMouseLeave={() => closeMenu()}>
                <div className={offer ? 'd-none' : 'offer'}>
                    <p className="text-center mb-0">
                        <IconDiscount size={20} className="me-2" />
                        50 درصد تخفیف لپتاپ برای مشتریان عزیز
                        <IconX size={20} className="ms-2" onClick={ChangeStatus} />
                    </p>
                </div>
                <nav className="navbar navbar-expand-md bg-light">
                    <section className="container">
                        <Link className="navbar-brand" to="#">
                            <img alt="logo" src={require('./../../assets/media/logo/final.png')} className="logo-img" />
                        </Link>
                        <button className="navbar-toggler" type="button">
                            <IconUserCircle data-bs-toggle="modal" data-bs-target="#signup" size={32} className="mx-2" />
                            <IconShoppingBag size={32} className="me-0" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-between align-items-center" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                {RoutesClient.map((address) => (
                                    <HeaderNav child={address.child} show={address.show} key={address.address} label={address.label} address={address.address} />
                                ))}
                            </ul>
                            <div className="actions">
                                <div className="d-flex gx-4">
                                    <input type="search" className="form-control text-white font-medium py-1" placeholder="دنبال چی میگردی" />
                                    <IconUserCircle size={42} className="mx-2" />
                                    <IconShoppingBag size={42} />
                                </div>
                            </div>
                        </div>
                    </section>
                </nav>
                {isMenuOpen && (
                    <div className="container">
                        <div
                            className="collapse shadow bg-light header-collapse w-75 position-absolute container"
                            id="collapse-menu"
                            onMouseLeave={closeMenu}
                        >
                            <div className="container pt-3 pb-4">
                                <div className="row gy-3">
                                    {categoires?.map((category: any, index: number) => (
                                        <div className="col-lg-3 col-md-3" key={index}>
                                            <Link to={`/categories/${category.slug}`} className="text-decoration-none text-black">
                                                <div className="d-flex align-items-center">
                                                    <img src={category.icon} alt="category" className="category-img" />
                                                    <h6 className="ms-2 mb-0">{category.name}</h6>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </header>
            <MobileNav routes={RoutesClient} />
        </>
    )
}

export default Header;