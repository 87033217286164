// swiper
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
// styles
import 'swiper/css';
import './style/style.css'
import "swiper/css/pagination";
// child
import { SliderChild } from './child/child';


export function Slider(props: any) {
    return (
        <>
            {props.sliders ? (
                <>
                    <Swiper
                        spaceBetween={10}
                        loop={true}
                        slidesPerView={1}
                        pagination={{ clickable: true }}
                        speed={1500}
                        autoplay={{
                            delay: 2000,
                        }}
                        modules={[Pagination, Autoplay]}
                    >
                        {props.sliders?.map((slider: any, index: number) => (
                            <SwiperSlide key={slider.id + 'topslider'}>
                                <SliderChild image={slider.image} />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            ) : null}
        </>
    )
}