// style
import { IconBrandInstagram, IconBrandTelegram, IconBrandWhatsapp, IconBrandYoutube, IconClock, IconMail, IconPhone } from '@tabler/icons-react';
import './style/footer.css'
import { NavLink } from 'react-router';
import { RoutesClient } from '../../features/routes/routes';
const Footer = () => {
    return (
        <>
            <footer className="bg-dark py-5 pb-3">
                <section className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <img src={require('./../../assets/media/logo/logo.png')} alt="logo" className="brand-logo" />
                            <p className='mb-0 card-text text-white mt-3'>
                                «آکبند، جایی که تکنولوژی و اصالت به هم می‌رسند. ما با ارائه گارانتی معتبر و پشتیبانی فنی حرفه‌ای، اطمینان می‌دهیم که خرید شما از ما، خریدی هوشمندانه و مطمئن است.»
                            </p>
                        </div>
                        <div className="col-lg-3 text-lg-start text-center d-lg-inline-block d-none ps-5">
                            <h6 className='text-white  h5 fw-bold'>آکبند</h6>
                            <ul className='list-group px-0'>
                                {RoutesClient.map((link) => (
                                    <li className='list-group-item px-0'>
                                        <NavLink className="text-white text-decoration-none" to={link.address}>
                                            {link.label}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-lg-3 d-lg-inline-block d-none ps-5">
                            <h6 className='text-white h5 fw-bold'>تماس با ما</h6>
                            <ul className='list-group px-0'>
                                <li className='list-group-item px-0'>
                                    <NavLink className="text-white text-decoration-none" to={'#'}>
                                        تلگرام
                                    </NavLink>
                                </li>
                                <li className='list-group-item px-0'>
                                    <NavLink className="text-white text-decoration-none" to={'#'}>
                                        واتساپ
                                    </NavLink>
                                </li>
                                <li className='list-group-item px-0'>
                                    <NavLink className="text-white text-decoration-none" to={'#'}>
                                        ایتا
                                    </NavLink>
                                </li>
                                <li className='list-group-item px-0'>
                                    <NavLink className="text-white text-decoration-none" to={'#'}>
                                        اینستاگرام
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                        <div className="col-lg-3 d-lg-inline-block d-none ps-5">
                            <h6 className='text-white h5 fw-bold'>جواز ها</h6>
                            {/* @ts-ignore */}
                            {/* <a referrerPolicy='origin' target='_blank' href='https://trustseal.enamad.ir/?id=485532&Code=vkz8vfTS9AWlU3ksHoLjG0JnvrtzQUp2'><img referrerPolicy='origin' src='https://trustseal.enamad.ir/logo.aspx?id=485532&Code=vkz8vfTS9AWlU3ksHoLjG0JnvrtzQUp2' alt='' style={{ cursor: 'pointer', width: '150px', height: '150px', objectFit: 'cover' }} className='mt-4' code='vkz8vfTS9AWlU3ksHoLjG0JnvrtzQUp2' /></a> */}
                        </div>
                    </div>
                    <div className="connection-box py-3 pb-2 mt-3">
                        <div className="details">
                            <div className='d-flex align-items-center'>
                                <span className='text-white px-4 w-25 fw-bold d-lg-inline-block d-none'>راه های ارتباطی با آکبند</span>
                                <div className='card card-body footer-card py-4 w-100'>
                                    <div className='row justify-content-center gy-3'>
                                        <div className='col-md-4 text-center'>
                                            <div className='d-flex align-items-center justify-content-md-center justify-content-end'>
                                                <span className='fw-bold font-large'>شنبه تا پنجشنبه از 10</span>
                                                <div className='bg-black bg-icon rounded-circle ms-2'>
                                                    <IconClock className='text-white align-middle' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 text-center '>
                                            <div className='d-flex align-items-center justify-content-md-center justify-content-end'>
                                                <span className='fw-bold font-large'>amir@gmail.com</span>
                                                <div className='bg-black bg-icon rounded-circle ms-2'>
                                                    <IconMail className='text-white align-middle' />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4 text-center'>
                                            <div className='d-flex align-items-center justify-content-md-center justify-content-end'>
                                                <span className='fw-bold font-large'>+123456789</span>
                                                <div className='bg-black bg-icon rounded-circle ms-2'>
                                                    <IconPhone className='text-white align-middle' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="social d-flex justify-content-center text-white mb-0 py-4 pb-0">
                            <IconBrandInstagram size={32} />
                            <IconBrandTelegram size={32} className=' mx-3' />
                            <IconBrandWhatsapp size={32} className='me-3' />
                            <IconBrandYoutube size={32} />
                        </div>
                    </div>
                </section>
            </footer>
        </>
    )
}

export default Footer;