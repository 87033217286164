// link
import { NavLink } from "react-router-dom";

const HeaderNav = (props: object | any) => {
    return (
        <>
            {props.show ? (
                <>
                    {props.child ? (
                        <>
                            <li className="nav-item" key={props.address}>
                                <span data-bs-toggle="collapse" data-bs-target={props.address} className="nav-link text-black mx-1">
                                    {props.label}
                                </span>
                            </li>
                        </>
                    ) : (
                        <>
                            <li className="nav-item" key={props.address + 'address'}>
                                <NavLink className="nav-link text-black mx-1" to={props.address}>
                                    {props.label}
                                </NavLink>
                            </li>
                        </>
                    )}
                </>
            ) : null}
        </>
    )
}

export default HeaderNav;