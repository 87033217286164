// icon
import { IconHeart, IconShoppingBag, IconStar } from "@tabler/icons-react";
// link
import { Link } from "react-router";
// splitnumber
import { splitNumber } from "../../features/splitNumber/splitNumber";

const NewProducts = (props: any) => {
    return (
        <>
            <Link to={`/products/detail/${props.slug}`} className="text-decoration-none">
                <div data-aos="fade-right"
                    className="card new-product-card category-card border-0">
                    <div className="card-image rounded rounded-4 p-3">
                        <span className="badge bg-white text-dark icon">جدید</span>
                        <div className="d-flex justify-content-center">
                            <img src={props.primary_image} alt="product" />
                        </div>
                        <div className="icon like-place shadow">
                            <IconHeart size={18} />
                        </div>
                        <button className="btn btn-primary w-100 py-1 mt-3">
                            افزودن
                        </button>
                    </div>
                    <div className="card-body mt-1">
                        <div className="d-flex w-100 justify-content-between align-items-center">
                            <span className="text-dark w-50 text-split fw-bold font-medium-lg mb-0">
                                {props.name}
                            </span>
                            <div className="stars w-50 text-end text-warning font-medium-lg align-middle">
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                                <IconStar size={14} />
                            </div>
                        </div>
                        <p className="card-text text-split font-medium mb-0 mt-2">
                            {props.description}
                        </p>
                        <hr />
                        <div className="d-flex justify-content-between align-items-center">
                            <IconShoppingBag className="align-middle" />
                            {props.quantity_check?.price ? (
                                <span className="card-title price font-medium-lg">{splitNumber(props.quantity_check?.price)}  تومان</span>
                            ) : (
                                <span className="card-title text-danger fw-bold price font-medium-lg">نا موجود</span>
                            )}
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default NewProducts;