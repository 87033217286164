// style
import { IconBrandGmail, IconBrandInstagram, IconBrandTelegram, IconBrandWhatsapp } from '@tabler/icons-react';
// style
import './style/style.css'
// controller
import useSendData from '../../hook/sendData';
import { PostContactUs } from '../../services/client/contactUs/postContactUs';
// form
import { useForm } from 'react-hook-form';
// error
import { onError } from '../../features/error/error';
// toast
import { ToastContainer } from 'react-toastify';

const ContactUs = () => {
    const { register, handleSubmit }: any = useForm<any>();
    let sendData;
    const { send, pending } = useSendData(sendData, PostContactUs, 'فرم با موفقیت ارسال شد', null, null)
    const onSubmit = (data: any) => {
        send(data);
    }
    return (
        <>
            <section className="contactus bg-white py-5">
                <div className="container">
                    <div className="row gy-4 overflow-hidden">
                        <div className="col-md-6 order-md-0 order-1" data-aos="fade-left">
                            <form className="mt-4" onSubmit={handleSubmit(onSubmit, onError)}>
                                <h1 className='fw-bold card-title h4'>تماس با ما</h1>
                                <p className='mt-3'>
                                    برای ارتباط با ما، می‌توانید از طریق تلفن یا ایمیل در هر زمان با ما در تماس باشید. تیم پشتیبانی آکبند آماده پاسخگویی به شماست
                                </p>
                                <div className="row gy-4">
                                    <div className="col-md-6">
                                        <fieldset className="form-floating">
                                            <input id="username" type="text" placeholder="نام و نام خانوادگی" className="form-control"
                                                {...register('name', { required: 'فیلد نام الزامی است' })}
                                            />
                                            <label htmlFor="username" className="form-label">
                                                نام و نام خانوادگی
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div className="col-md-6">
                                        <fieldset className="form-floating">
                                            <input id="email" type="email" placeholder="ایمیل" className="form-control"
                                                {...register('email', { required: 'فیلد ایمیل الزامی است' })}
                                            />
                                            <label htmlFor="email" className="form-label">
                                                ایمیل
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12">
                                        <fieldset className="form-floating">
                                            <input id="title" type="text" placeholder="موضوع" className="form-control"
                                                {...register('subject', { required: 'فیلد موضوع الزامی است' })}
                                            />
                                            <label htmlFor="title" className="form-label">
                                                موضوع
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div className="col-md-12">
                                        <fieldset className="form-floating">
                                            <textarea className="form-control" name="title" id="title" placeholder="توضیحات"
                                                {...register('text', { required: 'فیلد توضیحات الزامی است' })}
                                            ></textarea>
                                            <label htmlFor="title" className="form-label">
                                                توضیحات
                                            </label>
                                        </fieldset>
                                    </div>
                                    <div className="col-12">
                                        <button disabled={pending} className="btn btn-primary">
                                            {pending ? 'درحال ارسال...' : 'ارسال'}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-md-6 order-md-1 order-0" data-aos="fade-right">
                            <img src={require('./../../assets/media/banner/contactus.png')} alt="" className="contact-img w-100" />
                            <div className='row justify-content-md-end justify-content-center'>
                                <div className='col-md-5 col-10 px-4'>
                                    <div data-aos="fade-up" className='card card-body contact-card border-0 shadow'>
                                        <p className='text-site text-center fw-bold'>راه های ارتباطی</p>
                                        <div className='d-flex justify-content-center'>
                                            <IconBrandTelegram className='text-primary' size={30} />
                                            <IconBrandInstagram className='mx-3 text-danger' size={30} />
                                            <IconBrandWhatsapp className='me-3 text-success' size={30} />
                                            <IconBrandGmail size={30} className='text-warning' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <article className="end-banner">
                <img src={require('./../../assets/media/banner/WATER-COOLING-SLIDER2 2.png')} className="img-fluid banner-small w-100" alt="banner" />
            </article>
            <ToastContainer/>
        </>
    )
}

export default ContactUs;