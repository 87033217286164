import { useQuery } from "@tanstack/react-query";

const useGetData = (key: any, service: any) => {
    const { data, isLoading, isError, refetch }: any = useQuery<any>({
        queryKey: key,
        queryFn: service,
    });
    return { data, isLoading, isError, refetch }
}

export default useGetData;