// tosat options
import { toast } from "react-toastify";
import { toastOptions } from "../toast/toast";

export const onError = (errors: any) => {
    const uniqueErrorMessages = new Set<string>();
    Object.values(errors).forEach((error: any) => {
        if (error?.message) {
            uniqueErrorMessages.add(error.message as string);
        }
    });
    uniqueErrorMessages.forEach((message) => {
        toast.error(message, toastOptions);
    });
};