import { Slider } from "../../components/slider/slider";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Keyboard } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/pagination';
// style
import './style/home.css'
// controller and hooks
import useGetData from "../../hook/getData";
import { GetBanners } from "../../services/client/banner/getBanner";
import { GetCategories } from "../../services/client/categories/getCategories";
import { GetProducts } from "../../services/client/products/getProducts";
// link
import { Link } from "react-router";
// components
import Category from "../../components/category/category";
import BannerCategory from "../../components/bannerCategory/bannerCategory";
import NewProducts from "../../components/newProduct/newProducts";
import ProductsCard from "../../components/products/products";
// options
import { ProductSettings, ProductsSettings } from "../../features/swiperOptions/swiperOptions";
import DiscountProduct from "../../components/discountProduct/discountProduct";

const Home = () => {
    const { data: sliders } = useGetData(['sliders'], GetBanners)
    const { data: categoires } = useGetData(['categories'], GetCategories)
    const { data: Products } = useGetData(['products'], GetProducts)
    return (
        <>
            <section className="banner-parent bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <Slider sliders={sliders} />
                        </div>
                        <div className="col-md-4 d-md-inline-block d-none">
                            <img src={require('./../../assets/media/banner/banner_4.jpg')} alt="banner" className="img-fluid banner-top-box rounded rounded-4" />
                            <img src={require('./../../assets/media/banner/banner_5.jpg')} alt="banner" className="img-fluid banner-top-box rounded rounded-4 mt-4" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="category bg-white py-3 mt-md-0 mt-2">
                <div className="container">
                    {categoires ? (
                        <>
                            <Swiper
                                slidesPerView={4}
                                centeredSlides={false}
                                slidesPerGroupSkip={1}
                                spaceBetween={30}
                                grabCursor={true}
                                keyboard={{
                                    enabled: true,
                                }}
                                breakpoints={{
                                    176: {
                                        slidesPerView: 2,
                                    },
                                    768: {
                                        slidesPerView: 4,
                                    },
                                    1000: {
                                        slidesPerView: 6,
                                    },
                                }}
                                navigation={true}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Keyboard]}
                                className="mySwiper"
                            >
                                {categoires?.map((category: any) => (
                                    <SwiperSlide key={category.id + 'category'}>
                                        <Category slug={category.slug} icon={category.icon} name={category.name} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </>
                    ) : null}
                </div>
            </section>
            <section className="new-products bg-white py-md-4 py-2 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">جدید ترین ها</h6>
                    {Products?.latestProducts ? (
                        <Swiper
                            {...ProductSettings}
                        >
                            {Products?.latestProducts?.map((product: any) => (
                                <SwiperSlide key={product.id + 'newProduct'}>
                                    <NewProducts slug={product.slug} name={product.name} primary_image={product.primary_image} description={product.description} quantity_check={product.quantity_check} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : null}
                </div>
            </section>
            <section className="banners bg-white py-3 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">تجربه بهترین خرید با ما</h6>
                    <div className="row gy-3 overflow-x-hidden">
                        <div className="col-12">
                            <Link to={'/about'} className="text-decoration-none">
                                <div className="banner-bg px-4">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-md-6 col-8">
                                            <h5 className="text-white fw-bold">
                                                آکبند | خرید بهترین کیبورد و لوازم جانبی
                                            </h5>
                                            <p className="card-text text-white mt-3 mb-0 d-md-block d-none">
                                                با آکبند بهترین کیبورد هارو با مقطوع ترین قیمت پیدا میکنی
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {categoires?.length <= 4 ? (
                            <>
                                {categoires?.map((category: any, index: number) => (
                                    <BannerCategory icon={category.icon} name={category.name} slug={category.slug} index={index} />
                                ))}
                            </>
                        ) : null}
                        <div className="col-12">
                            <Link to={'/products'} className="text-decoration-none">
                                <div className="banner-bg banner-bg-last px-4">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-md-6 col-8">
                                            <h5 className="text-white fw-bold">
                                                محصولات | با ما بهترین ها را تجربه کنید
                                            </h5>
                                            <p className="card-text text-white mt-3 mb-0 d-md-block d-none">
                                                با آکبند بهترین کیبورد هارو با مقطوع ترین قیمت پیدا میکنی
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="new-products more-sell bg-white py-5 pb-3">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">شگفتانه</h6>
                    {Products?.latestProducts ? (
                        <Swiper
                            {...ProductsSettings}
                        >
                            {Products?.hasDiscountProducts?.map((product: any) => (
                                <SwiperSlide key={product.id + 'newProduct'}>
                                    <DiscountProduct slug={product.slug} name={product.name} primary_image={product.primary_image} description={product.description} quantity_check={product.quantity_check} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    ) : null}
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <img src={require('./../../assets/media/banner/bannerEnd.webp')} alt="banner" className="img-fluid banner-small rounded rounded-4 w-100" />
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <div className="d-flex justify-content-between mb-3 align-items-center">
                        <h6 className="fw-bold h5 mb-0">محصولات</h6>
                        <Link to="/products" className="btn btn-primary">مشاهده همه</Link>
                    </div>
                    <div className="new-products">
                        {Products?.products ? (
                            <Swiper
                                {...ProductsSettings}
                            >
                                {Products?.products?.map((product: any) => (
                                    <SwiperSlide key={product.id + 'products'}>
                                        <ProductsCard slug={product.slug} name={product.name} primary_image={product.primary_image} description={product.description} quantity_check={product.quantity_check} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        ) : null}
                    </div>
                </div>
            </section>
            <section className="bg-white py-5 pb-3">
                <div className="container">
                    <div className="row gy-4">
                        <div className="col-md-6 mt-3">
                            <img src={require('./../../assets/media/banner/banner_top_1.webp')} alt="banner" className="img-fluid rounded rounded-4" />
                        </div>
                        <div className="col-md-6 mt-3">
                            <img src={require('./../../assets/media/banner/banner_top_2.webp')} alt="banner" className="img-fluid rounded rounded-4" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="bg-white brands py-5">
                <div className="container">
                    <h6 className="mb-3 fw-bold h5">برند های پرفروش</h6>
                    <Swiper
                        slidesPerView={4}
                        centeredSlides={false}
                        slidesPerGroupSkip={1}
                        loop={true}
                        autoplay={{
                            delay: 1500
                        }}
                        spaceBetween={30}
                        grabCursor={true}
                        keyboard={{
                            enabled: true,
                        }}
                        breakpoints={{
                            176: {
                                slidesPerView: 3,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            1000: {
                                slidesPerView: 6,
                            },
                        }}
                        modules={[Keyboard, Autoplay]}
                        className="mySwiper"
                    >
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/msi.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/asus.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/cosair.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/deepcool.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/intel.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/cosair.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/deepcool.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/msi.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/asus.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                        <SwiperSlide className="text-center">
                            <img src={require('./../../assets/media/icon/intel.png')} alt="brand" className="img-fluid" />
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
            <article className="end-banner">
                <img src={require('./../../assets/media/banner/WATER-COOLING-SLIDER2 1.webp')} className="img-fluid banner-small w-100" alt="banner" />
            </article>
        </>
    )
}

export default Home;