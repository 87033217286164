import { useMutation } from "@tanstack/react-query";
// toast
import { toast } from "react-toastify";
import { toastOptions } from "../features/toast/toast";
// @ts-ignore
import Cookies from "js-cookie";
const useSendData = (action: any, sevice: any, message?: any, link?: any, token?: any, refetch?: any) => {
    action = useMutation({
        mutationFn: sevice,
        onSuccess: (data: any) => {
            toast.success(`${message}.`, toastOptions);
            if (data.token) {
                Cookies.set(token, data.token);
            }
            if (link) {
                window.open(link, '_self')
            }
            if (refetch) {
                refetch();
            }
        },
        onError: (error: any) => {
            const errors: any = error.response.data?.message;
            toast.error(error.response.data?.message, toastOptions);
            if (errors) {
                for (const [, messages] of Object.entries(errors)) {
                    (messages as string[]).forEach((message) => {
                        toast.error(message, toastOptions);
                    });
                }
            }
        },
    });
    let send = action.mutate;
    let pending = action.isPending;
    return { send, pending };
}

export default useSendData;