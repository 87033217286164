import { Link } from "react-router";

const Category = (props: any) => {
    return (
        <>
            <Link to={`/categories/${props.slug}`} className="text-decoration-none text-black">
                <div className="card card-body border-0 category-card" >
                    <div className="d-flex justify-content-center">
                        <div className="image-box shadow">
                            <img alt="category" src={props.icon} />
                        </div>
                    </div>
                    <p className="text-center mb-0 fw-bold font-medium-lg mt-3">
                        {props.name}
                    </p>
                </div>
            </Link>
        </>
    )
}

export default Category;