// icons
import { IconShoppingBag, IconStar } from "@tabler/icons-react";
// spliter
import { splitNumber } from "../../features/splitNumber/splitNumber";
// icon
import { Link } from "react-router";

const DiscountProduct = (props: any) => {
    return (
        <>
            <Link to={`/products/detail/${props.slug}`} className="text-decoration-none">
                <div data-aos="fade-left"
                    className="card new-product-card category-card border-0">
                    <div className="card-image rounded rounded-4 p-3">
                        <div className="d-flex justify-content-center">
                            <img className="img-fluid" src={props.primary_image} alt="product" />
                        </div>
                    </div>
                    <div className="card-body mt-0">
                        <div className="d-flex justify-content-between align-items-center">
                            <span className="text-dark w-50 text-split fw-bold font-medium-lg mb-0">
                                {props.name}
                            </span>
                            <div className="stars w-100 text-end text-warning font-medium-lg align-middle">
                                <IconStar className="text-primary" size={14} />
                                <IconStar className="text-primary" size={14} />
                                <IconStar className="text-primary" size={14} />
                                <IconStar className="text-primary" size={14} />
                                <IconStar className="text-primary" size={14} />
                            </div>
                        </div>
                        <hr />
                        <p className="card-text font-medium mb-0 mt-2 text-split">
                            {props.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                            <p className="card-title price mb-0 fw-bold font-medium-lg mt-2">
                                {props.quantity_check?.price ? (
                                    <span className="card-title price font-medium-lg">{splitNumber(props.quantity_check?.price)}  تومان</span>
                                ) : (
                                    <span className="card-title text-danger fw-bold price font-medium-lg">نا موجود</span>
                                )}
                            </p>
                            <button disabled={!props.quantity_check?.price} className="btn btn-primary py-1 font-medium-lg">
                                <IconShoppingBag size={16} className="align-middle me-2" />
                                خرید
                            </button>
                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default DiscountProduct;