// icon
import { IconArrowBadgeLeft } from "@tabler/icons-react";
// link
import { Link } from "react-router";

const BannerCategory = (props: any) => {
    return (
        <>
            <div key={props.id + 'banner'} className="col-md-3 col-6" data-aos="zoom-in">
                <Link to={`/categories/${props.slug}`} className="text-decoration-none">
                    <div className={`banner-bg banner-bg-se-${props.index} px-3 pb-3`}>
                        <div className="row h-100 align-items-end">
                            <div className="col-12">
                                <h5 className="text-white fw-bold">
                                    {props.name}
                                </h5>
                                <p className="text-start mb-0 fw-bold font-medium text-white">
                                    مشاهده همه
                                    <IconArrowBadgeLeft size={16} className="align-middle" />
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </>
    )
}

export default BannerCategory;