// style
import './style/style.css'
// links
import { useParams } from 'react-router';
// icon
import { IconFilter, IconX } from "@tabler/icons-react";
// hook and controller
import useGetData from '../../hook/getData';
import { GetCategoryProducts } from '../../services/client/categories/getCategoryProducts';
// components
import ProductsCard from '../../components/products/products';
// state
import { useEffect, useState } from 'react';
// client
import { ClientOption } from '../../config/clientOption';
// axios
import axios from 'axios';

const Products = () => {
    const params = useParams();
    let [queryParam, setQueryParam] = useState<any>('');
    let [active, setActive] = useState<any>('');
    let [productCategory, setProduct] = useState<any>('')
    const { data: products, isLoading: productLoading } = useGetData(['categories', params.slug], GetCategoryProducts);
    useEffect(() => {
        setProduct(products);
    }, [products])
    const getFilter = (paramsData: any) => {
        axios.get(`${process.env.REACT_APP_API_URL}categories/${params.slug}?${paramsData}`, ClientOption)
            .then((response) => {
                setProduct(response.data.data)
            })
            .catch((error) => [
                console.log(error)
            ])
    }
    const changeProduct = (version: any, id: any, value: any, event: any) => {
        let param: any = queryParam;
        if (version === 'attr') {
            const regex = new RegExp(`attribute\\[${id}\\]=([^&]*)`);
            const match = param.match(regex);
            let values: string[] = match ? match[1].split('-') : [];

            if (event.target.checked) {
                if (!values.includes(value)) {
                    values.push(value);
                }
            } else {
                values = values.filter(v => v !== value);
            }

            param = param.replace(regex, '').replace(/^&|&$/g, '');

            if (values.length > 0) {
                param += (param ? '&' : '') + `attribute[${id}]=${values.join('-')}`;
            }
        }
        if (version === 'varia') {
            if (event.target.checked) {
                param = param ? `${param}&variation=${value}` : `variation=${value}`;
            } else {
                param = param.replace(`variation=${value}`, '').replace(/^&|&$/g, '');
            }
        }
        if (version === 'search') {
            param = param ? `${param}&search=${event.target.value}` : `search=${event.target.value}`;
        }

        if (version === 'sort' && value) {
            param = param ? `${param}&sortBy=${value}` : `sortBy=${value}`;
            setActive(value);
        }
        setQueryParam(param);
        getFilter(param);
    };
    return (
        <>
            <section className="bg-white py-md-5 py-4">
                <div className="container">
                    <div className="row gy04">
                        <div className="col-lg-3 d-md-inline-block d-none col-md-4">
                            <div className="card product-swicher position-sticky card-body border-0 shadow rounded rounded-4">
                                <input onKeyUp={(e) => changeProduct('search', null, null, e)} className="form-control py-3" type="search" placeholder="جست و جو..."></input>
                                <div className="accordion mt-3" id="accordionExample">
                                    {productCategory?.filters?.attributes?.map((item: any, index: number) => (
                                        <div key={item.id + 'attr'} className="accordion-item">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button shadow-none bg-white " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls="collapseOne">
                                                    {item.name}
                                                </button>
                                            </h2>
                                            <div id={`collapse${index}`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                                <div className="accordion-body pt-0">
                                                    <ul className="list-group px-0 py-0 mt-0">
                                                        {item.attribute_values?.map((value: any) => (
                                                            <li className="list-group-item px-0">
                                                                <div className="d-flex font-medium-lg justify-content-between">
                                                                    <span className="text-muted">{value.value}</span>
                                                                    <div className="form-switch">
                                                                        <input onChange={(e) => changeProduct('attr', item.id, value.value, e)} className="form-check-input" type="checkbox"></input>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {productCategory?.filters?.variation ? (
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button shadow-none bg-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    {productCategory?.filters?.variation?.name}
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body pt-0">
                                                    <ul className="list-group px-0 py-0 mt-0">
                                                        {productCategory?.filters?.variation.variation_values?.map((value: any) => (
                                                            <li className="list-group-item px-0">
                                                                <div className="d-flex font-medium-lg justify-content-between">
                                                                    <span className="text-muted">{value.value}</span>
                                                                    <div className="form-switch">
                                                                        <input onChange={(e) => changeProduct('varia', value.attribute_id, value.value, e)} className="form-check-input" type="checkbox"></input>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-8">
                            <div className="card card-body rounded rounded-4 border-0 shadow">
                                <div className="category-type">
                                    <ul className="nav mt-1">
                                        <li className="nav-item filter-font">
                                            مرتب سازی بر اساس:
                                        </li>
                                        <li className={active === 'latest' ? 'nav-item px-md-4 px-2 text-site fw-bold' : 'nav-item px-md-4 px-2 filter-font'} onClick={() => changeProduct('sort', null, 'latest', null)}>
                                            جدیدترین
                                        </li>
                                        <li className={active === 'oldest' ? 'nav-item px-md-4 px-2 filter-font text-site fw-bold' : 'nav-item px-md-4 px-2 filter-font'} onClick={() => changeProduct('sort', null, 'oldest', null)}>
                                            قدیمی ترین
                                        </li>
                                        <li className={active === 'max' ? 'nav-item px-md-4 px-2 filter-font text-site fw-bold' : 'nav-item px-md-4 px-2 filter-font'} onClick={() => changeProduct('sort', null, 'max', null)}>
                                            گران ترین
                                        </li>
                                        <li className={active === 'min' ? 'nav-item px-md-4 px-2 filter-font text-site fw-bold' : 'nav-item px-md-4 px-2 filter-font'} onClick={() => changeProduct('sort', null, 'min', null)}>
                                            ارزان ترین
                                        </li>
                                    </ul>
                                </div>
                                <div className="row gy-4 mt-1">
                                    {productCategory?.products ? (
                                        <>
                                            {productCategory?.products?.map((product: any) => (
                                                <div key={product.id} className='col-lg-4 col-md-6'>
                                                    <ProductsCard slug={product.slug} name={product.name} primary_image={product.primary_image} description={product.description} quantity_check={product.quantity_check} />
                                                </div>
                                            ))}
                                        </>
                                    ) :
                                        (
                                            <>
                                                {!productLoading ? (
                                                    <div className='row justify-content-center'>
                                                        <div className='col-md-6'>
                                                            <img src={require('./../../assets/media/global/404.png')} alt="404" className='error-page-img' />
                                                            <p className='mt-3 text-center fw-bold large'>
                                                                محصولی پیدا نشد...
                                                            </p>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="modal fade" id="swicher">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content">
                        <div className="modal-header d-flex justify-content-between">
                            <h5 className='text-site mb-0 fw-bold'>انتخاب دسته بندی</h5>
                            <IconX data-bs-dismiss="modal" />
                        </div>
                        <div className="modal-body">
                            <input onKeyUp={(e) => changeProduct('search', null, null, e)} className="form-control py-3" type="search" placeholder="جست و جو..."></input>
                            <div className="accordion mt-3" id="accordionExample">
                                {productCategory?.filters?.attributes?.map((item: any, index: number) => (
                                    <div key={item.id + 'attrmob'} className="accordion-item">
                                        <h2 className="accordion-header" id="headingOne">
                                            <button className="accordion-button shadow-none bg-white " type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}Mob`} aria-expanded="true" aria-controls="collapseOne">
                                                {item.name}
                                            </button>
                                        </h2>
                                        <div id={`collapse${index}Mob`} className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body pt-0">
                                                <ul className="list-group px-0 py-0 mt-0">
                                                    {item.attribute_values?.map((value: any) => (
                                                        <li className="list-group-item px-0">
                                                            <div className="d-flex font-medium-lg justify-content-between">
                                                                <span className="text-muted">{value.value}</span>
                                                                <div className="form-switch">
                                                                    <input onChange={(e) => changeProduct('attr', item.id, value.value, e)} className="form-check-input" type="checkbox"></input>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {productCategory?.filters?.variation ? (
                                    <div className="accordion-item">
                                        <h2 className="accordion-header" id="headingTwo">
                                            <button className="accordion-button shadow-none bg-white collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwoMob" aria-expanded="false" aria-controls="collapseTwo">
                                                {productCategory?.filters?.variation?.name}
                                            </button>
                                        </h2>
                                        <div id="collapseTwoMob" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                            <div className="accordion-body pt-0">
                                                <ul className="list-group px-0 py-0 mt-0">
                                                    {productCategory?.filters?.variation.variation_values?.map((value: any) => (
                                                        <li key={value.value + 'value'} className="list-group-item px-0">
                                                            <div className="d-flex font-medium-lg justify-content-between">
                                                                <span className="text-muted">{value.value}</span>
                                                                <div className="form-switch">
                                                                    <input onChange={(e) => changeProduct('varia', value.attribute_id, value.value, e)} className="form-check-input" type="checkbox"></input>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button data-bs-dismiss="modal" className='w-100 btn btn-primary'>
                                اعمال فیلتر ها
                            </button>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bg-white d-md-none d-inline-block'>
                <button className='swicher-btn btn shadow' data-bs-toggle="modal" data-bs-target="#swicher">
                    <IconFilter />
                </button>
            </section>
        </>
    )
}

export default Products;